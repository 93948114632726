'use client';

import Script from 'next/script';
import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { Userpilot } from 'userpilot';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

// ----------------------------------------------------------------------

const DISABLED_TRACKING_EMAIL_SUBSTRINGS = ['@tracklution', '@leadspark']; // We have multiple TLDs, so check without it.

let contactInfoSent = false;
let disableTracking = false;

export function fireContactInfo(data) {
  console.log('fireContactInfo');
  if (Object.keys(data).length === 0 || contactInfoSent) {
    return;
  }
  const email = data.email ?? null;
  const name = String(data.name ?? '');
  const firstName = name.substring(0, name.indexOf(' '));
  const lastName = name.substring(name.indexOf(' ') + 1);

  if (DISABLED_TRACKING_EMAIL_SUBSTRINGS.some((substring) => String(email).includes(substring))) {
    disableTracking = true;
    return;
  }

  // window.tlq?.('set', 'ContactInfo', {
  //   email,
  //   phoneNumber: data.phone_number ?? data.phone ?? null,
  //   firstName: data.firstName ?? firstName,
  //   lastName: data.lastName ?? lastName,
  // });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    email,
    phoneNumber: data.phone_number ?? data.phone ?? null,
    firstName: data.firstName ?? firstName,
    lastName: data.lastName ?? lastName,
  });

  Userpilot.identify(email, {
    name: data.firstName ?? firstName,
    email: data.email ?? null,
  });

  amplitude.setUserId(data.email ?? null);

  contactInfoSent = true;
}

export function fireWatchDemo() {
  if (disableTracking) {
    return;
  }
  console.log('fireWatchDemo');
  window.tlq?.('track', 'WatchDemo');

  Userpilot.track('WatchDemo');
  amplitude.track('WatchDemo');
}

export function fireWatchDemoPartial() {
  if (disableTracking) {
    return;
  }
  console.log('fireWatchDemoPartial');
  window.tlq?.('track', 'WatchDemoPartial');

  Userpilot.track('WatchDemoPartial');
  amplitude.track('WatchDemoPartial');
}

export function fireWatchDemoCompleted() {
  if (disableTracking) {
    return;
  }
  console.log('fireWatchDemoComplete');
  window.tlq?.('track', 'WatchDemoComplete');

  Userpilot.track('WatchDemoComplete');
  amplitude.track('WatchDemoComplete');
}

export function fireStart() {
  console.log('fireStart', disableTracking);
  if (disableTracking) {
    return;
  }
  // firePurchase(0.1);
  window.tlq?.('track', 'CTA');

  Userpilot.track('CTA', { url: window.location.href });
  amplitude.track('CTA');
}

export function fireContactOpen() {
  if (disableTracking) {
    return;
  }
  console.log('fireContactOpen');
  // firePurchase(0.1);
  window.tlq?.('track', 'ContactOpen');

  Userpilot.track('ContactOpen');
  amplitude.track('ContactOpen');
}

export function fireContactSubmit() {
  if (disableTracking) {
    return;
  }
  console.log('fireContactSubmit');
  // firePurchase(3);
  window.tlq?.('track', 'SignupDemo');

  Userpilot.track('SignupDemo');
  amplitude.track('SignupDemo');
}

export function fireRegistered(method) {
  if (disableTracking) {
    return;
  }
  console.log('fireRegistered');
  // firePurchase(2);
  window.tlq?.('track', 'Signup');

  Userpilot.track('Signup', { method });
  amplitude.track('Signup', { method });

  window.tlq?.('set', 'ContactInfo', {
    onboardingStatus: 'Signup',
  });
}

export function fireCheckedUrl() {
  if (disableTracking) {
    return;
  }
  console.log('fireCheckedUrl');
  // firePurchase(3);
  window.tlq?.('track', 'CheckUrl');

  Userpilot.track('CheckUrl');
  amplitude.track('CheckUrl');
}

export function fireInstallationMethodSelected(method) {
  if (disableTracking) {
    return;
  }
  console.log('fireInstallationMethodSelected', method);

  window.tlq?.('set', 'ContactInfo', {
    installationMethod: method,
  });

  // firePurchase(3);
  window.tlq?.('track', 'SelectInstallationMethod');

  Userpilot.track('SelectInstallationMethod', { method });
  amplitude.track('SelectInstallationMethod', { method });
}

export function fireCreatedFirstCompany() {
  if (disableTracking) {
    return;
  }
  console.log('fireCreatedFirstCompany');
  // firePurchase(3);
  window.tlq?.('track', 'SignupCompany');

  Userpilot.track('SignupCompany');
  amplitude.track('SignupCompany');
}

export function fireCreatedContainer(isFirst = false, containerData = null) {
  if (disableTracking) {
    return;
  }
  console.log('fireCreatedContainer', isFirst, containerData);

  window.tlq?.('set', 'ContactInfo', {
    trackingContainerHash: containerData?.hash,
    trackingContainerTitle: containerData?.title,
    trackingWebsiteURL: containerData?.website_domain,
  });

  window.tlq?.('track', 'TC');

  Userpilot.track('TC');
  amplitude.track('TC');

  if (isFirst) {
    console.log('fireCreatedFirstContainer');
    // firePurchase(4);
    window.tlq?.('track', 'SignupTC');

    Userpilot.track('SignupTC');
    amplitude.track('SignupTC');

    window.tlq?.('set', 'ContactInfo', {
      onboardingStatus: 'SignupTC',
    });
  }
}

let installedContainerFired = false;

export function fireInstalledContainer(isFirst = false) {
  if (disableTracking) {
    return;
  }
  if (installedContainerFired) {
    return;
  }
  installedContainerFired = true;

  console.log('fireInstalledContainer', isFirst);
  window.tlq?.('track', 'TCData');

  Userpilot.track('TCData');
  amplitude.track('TCData');

  if (isFirst) {
    console.log('fireInstalledFirstContainer');
    firePurchase(50, true);
    window.tlq?.('track', 'SignupTCData');

    Userpilot.track('SignupTCData');
    amplitude.track('SignupTCData');

    window.tlq?.('set', 'ContactInfo', {
      onboardingStatus: 'SignupTCData',
    });
  }
}

export function fireCreatedConnector(type, isFirst = false) {
  if (disableTracking) {
    return;
  }
  console.log('fireCreatedConnector', isFirst);

  window.tlq?.('track', 'Connector');

  Userpilot.track('Connector', { type });
  amplitude.track('Connector', { type });

  if (isFirst) {
    console.log('fireCreatedFirstConnector');
    // firePurchase(4);
    window.tlq?.('track', 'SignupConnector');

    Userpilot.track('SignupConnector', { type });
    amplitude.track('SignupConnector', { type });

    window.tlq?.('set', 'ContactInfo', {
      onboardingStatus: 'SignupConnector',
    });
  }
}

// ----------------------------------------------------------------------

export function firePageView() {
  if (disableTracking) {
    return;
  }
  // console.log('track', 'PageView', disableTracking);

  window.tlq?.('track', 'PageView');

  Userpilot.track('PageView', { url: window.location.href });
}

export function firePurchase(conversionValue = 1, isFinal = false) {
  console.log('track', 'Purchase', {
    value: conversionValue,
    currency: 'EUR',
    isPartial: !isFinal,
  });

  window.tlq?.('track', 'Purchase', {
    value: conversionValue,
    currency: 'EUR',
    isPartial: !isFinal,
  });

  window.rdt?.('track', 'Purchase', { value: conversionValue, currency: 'EUR' });

  window.qp?.('track', 'Purchase', { value: conversionValue });

  Userpilot.track('Purchase', { value: conversionValue, currency: 'EUR' });
  amplitude.track('Purchase', { value: conversionValue, currency: 'EUR' });
}

let previousPathname = null;

const Tracking = () => {
  const pathname = usePathname();

  useEffect(() => {
    if (previousPathname === pathname) {
      return;
    }
    previousPathname = pathname;
    firePageView();
  }, [pathname]);

  const testInLocal = false;
  const isLocal = typeof window !== 'undefined' && window.location.hostname === 'localhost';
  if (isLocal && !testInLocal) {
    disableTracking = true;
    return null;
  }

  Userpilot.initialize('NX-df4daa68');

  if (typeof window !== 'undefined') {
    const sessionReplayTracking = sessionReplayPlugin();
    amplitude.add(sessionReplayTracking);
    amplitude.init('f8758bfacf6df25b02527fd8966ab42d');
  }

  return (
    <>
      <Script id="tracklution">
        {`
      !function(f,b,e,v,n,t,s)
    {if(f.tlq)return;n=f.tlq=function(){n.callMethod?
        n.callMethod(arguments):n.queue.push(arguments)};
        if(!f._tlq)f._tlq=n;n.push=n;n.loaded=!0;n.version='1.0';n.src=v;
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s);t.onerror=function(){
        v='https://tracking.leadspark.io/js/script-dynamic.js?version=2023-10-04';
        f._tlq.src=v;t=b.createElement(e);t.async=!0;t.src=v;
        s.parentNode.insertBefore(t, s)
        }}(window,document,'script',
        '${isLocal ? 'http://dev.fi' : 'https://tralut.tracklution.com'}/js/script-dynamic.js?version=2023-10-04')

    tlq('init', 'LS-70801101-0', {api: true});
        `}
      </Script>
      <Script
        id="socialintents-chat"
        src="https://www.socialintents.com/api/socialintents.1.3.js#2c9fab358b409e24018b4d16164a1410"
        async="async"
      />
      <Script id="reddit">
        {`
!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_dvghnzplseff', {"optOut":false,"useDecimalCurrencyValues":true});rdt('track', 'PageVisit');
        `}
      </Script>
      <Script id="quora">
        {`
!function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', 'https://a.quora.com/qevents.js');
qp('init', 'be651e1c405541efaa664d52acde3b10');
qp('track', 'ViewContent');
        `}
      </Script>
      <Script id="leadfeeder">
        {`
        (function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('kn9Eq4RdGbx8RlvP');
        `}
      </Script>
      {false && (
        <Script id="ms-clarity">
          {`
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "k58my344a1");
        `}
        </Script>
      )}
      {false && (
        <Script id="amplitude-config">
          {`
            !function(){"use strict";!function(e,t){var r=e.amplitude||{_q:[],_iq:{}};if(r.invoked)e.console&&console.error&&console.error("Amplitude snippet has been loaded.");else{var n=function(e,t){e.prototype[t]=function(){return this._q.push({name:t,args:Array.prototype.slice.call(arguments,0)}),this}},s=function(e,t,r){return function(n){e._q.push({name:t,args:Array.prototype.slice.call(r,0),resolve:n})}},o=function(e,t,r){e._q.push({name:t,args:Array.prototype.slice.call(r,0)})},i=function(e,t,r){e[t]=function(){if(r)return{promise:new Promise(s(e,t,Array.prototype.slice.call(arguments)))};o(e,t,Array.prototype.slice.call(arguments))}},a=function(e){for(var t=0;t<m.length;t++)i(e,m[t],!1);for(var r=0;r<g.length;r++)i(e,g[r],!0)};r.invoked=!0;var c=t.createElement("script");c.type="text/javascript",c.integrity="sha384-BWw9N39aN+4SdxZuwmRR0StXCLA+Bre4jR6bJt+pM1IqONNALC5rf25NkWMTyta5",c.crossOrigin="anonymous",c.async=!0,c.src="https://cdn.amplitude.com/libs/analytics-browser-2.9.3-min.js.gz",c.onload=function(){e.amplitude.runQueuedFunctions||console.log("[Amplitude] Error: could not load SDK")};var u=t.getElementsByTagName("script")[0];u.parentNode.insertBefore(c,u);for(var l=function(){return this._q=[],this},p=["add","append","clearAll","prepend","set","setOnce","unset","preInsert","postInsert","remove","getUserProperties"],d=0;d<p.length;d++)n(l,p[d]);r.Identify=l;for(var f=function(){return this._q=[],this},v=["getEventProperties","setProductId","setQuantity","setPrice","setRevenue","setRevenueType","setEventProperties"],y=0;y<v.length;y++)n(f,v[y]);r.Revenue=f;var m=["getDeviceId","setDeviceId","getSessionId","setSessionId","getUserId","setUserId","setOptOut","setTransport","reset","extendSession"],g=["init","add","remove","track","logEvent","identify","groupIdentify","setGroup","revenue","flush"];a(r),r.createInstance=function(e){return r._iq[e]={_q:[]},a(r._iq[e]),r._iq[e]},e.amplitude=r}}(window,document)}();

            amplitude.init("f8758bfacf6df25b02527fd8966ab42d");
        `}
        </Script>
      )}
    </>
  );
};

export default Tracking;
