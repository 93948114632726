'use client';

import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useRef } from 'react';
// icons
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Typography, Grid, Container, Stack, Box } from '@mui/material';
// components
import { Image, Iconify, CarouselArrows } from 'src/components/landing';

export const TESTIMONIALS = [
  {
    name: 'Heiman Safeen',
    role: 'Growth Officer',
    company: 'Ruokaboksi',
    review: (
      <>
        We started using Tracklution, as building our own server-side tracking would have required
        lots of resources. After switching from basic pixel tracking to {"Tracklution's"}{' '}
        server-side tracking, we{' '}
        <Box component="span" color="accent.main" sx={{ fontWeight: 600 }}>
          increased our conversion reporting accuracy by over 35%
        </Box>
        . This means more and higher quality data attributed to our campaigns. I also love how quick
        and easy it is to set up new traffic channels under Tracklution.
      </>
    ),
  },
  {
    name: 'Anni Salo',
    role: 'CEO',
    company: 'Tracklution',
    review: (
      <>
        We were able to{' '}
        <Box component="span" color="accent.main" sx={{ fontWeight: 600 }}>
          double our revenue from Meta Ads
        </Box>{' '}
        after switching from basic front-end pixel setup to Tracklution and its Server-Side
        Tracking. Tracklution enables us to flexibly try out new traffic acquisition channels
        without bothering our developers to integrate new Conversion APIs.
      </>
    ),
  },
  {
    name: 'Digital Marketing Agency',
    review: (
      <>
        All our customers had the same problem: How to set up server-side tracking. Thanks to
        Tracklution, we now have a plug-and-play solution for this. Tracklution helps us in buying
        better traffic to our customers and our customers to{' '}
        <Box component="span" color="accent.main" sx={{ fontWeight: 600 }}>
          get better results from their ad budgets.
        </Box>
      </>
    ),
  },
];

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 0, 6, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4, 0, 14, 0),
  },
  zoom: 0.9,
}));

// ----------------------------------------------------------------------

TestimonialsTech.propTypes = {
  maxWidth: PropTypes.any,
  variant: PropTypes.string,
};

export default function TestimonialsTech({ maxWidth = 'lg', variant }) {
  const theme = useTheme();
  const carouselRef = useRef(null);

  const carouselSettings = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <RootStyle>
      <Container maxWidth={maxWidth}>
        <Box sx={{ px: { md: 6 } }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={7}>
              <Slider ref={carouselRef} {...carouselSettings}>
                {TESTIMONIALS.map((testimonial, key) => (
                  <TestimonialItem key={key} testimonial={testimonial} variant={variant} />
                ))}
              </Slider>
            </Grid>

            <Grid
              item
              xs={12}
              md={5}
              sx={{
                display: { xs: 'none', md: 'block' },
              }}
            >
              <Image
                alt="travel testimonial"
                src="/assets/images/hero/tracklution-testimonials-1.png"
                sx={{ maxWidth: 396, ml: 'auto' }}
              />
            </Grid>
          </Grid>

          <CarouselArrows
            onNext={handleNext}
            onPrevious={handlePrevious}
            sx={{
              mt: 4,
              justifyContent: { xs: 'center', md: 'unset' },
            }}
          />
        </Box>
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

TestimonialItem.propTypes = {
  testimonial: PropTypes.shape({
    name: PropTypes.string,
    review: PropTypes.any,
    role: PropTypes.string,
    company: PropTypes.string,
  }),
  variant: PropTypes.string,
};

function TestimonialItem({ testimonial, variant }) {
  const { name, review, role, company } = testimonial;

  return (
    <Stack
      alignItems={{ xs: 'center', md: 'flex-start' }}
      sx={{ textAlign: { xs: 'center', md: 'left' } }}
    >
      <Iconify
        icon="carbon:quotes"
        sx={{
          width: { xs: 32, md: 48 },
          height: { xs: 32, md: 48 },
          opacity: 0.7,
          color: 'accent.main',
        }}
      />
      <Typography
        sx={{
          mt: { xs: 1.5, md: 2 },
          mb: { xs: 2, md: 5 },
          lineHeight: 1.75,
          fontSize: { xs: '1rem', md: '1.1rem' },
          color: variant === 'dark' ? 'common.white' : 'text.primary',
        }}
      >
        {review}
      </Typography>

      <Stack
        spacing={{ xs: 1, md: 2 }}
        alignItems="center"
        direction="row"
        sx={{ flexWrap: 'wrap', justifyContent: { xs: 'center', md: 'start' } }}
      >
        <Box sx={{ width: 8, height: 8, bgcolor: 'accent.main', borderRadius: '50%' }} />
        <Typography
          variant="h6"
          sx={{
            whiteSpace: 'nowrap',
            fontSize: { xs: '1rem', md: '1.1rem' },
            color: variant === 'dark' ? 'common.white' : 'text.primary',
          }}
        >
          {name}
        </Typography>
        {role && (
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              whiteSpace: 'nowrap',
              fontSize: { xs: '1rem', md: '1.1rem' },
              color: variant === 'dark' ? 'common.white' : 'text.secondary',
            }}
          >
            {role}
          </Typography>
        )}
        {company && (
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              whiteSpace: 'nowrap',
              fontSize: { xs: '1rem', md: '1.1rem' },
              color: variant === 'dark' ? 'common.white' : 'text.secondary',
            }}
          >
            {company}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
