'use client';

// next
// @mui
import { styled } from '@mui/material/styles';
import { Container, Stack, Typography } from '@mui/material';
// routes
// utils
// components
import { Image } from 'src/components/landing';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useResponsive } from '../../../hooks/use-responsive';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  background: theme.palette.background.neutral,
  padding: theme.spacing(2.5, 0, 1.5, 0),
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2.5, 0, 5, 0),
  },
}));

// ----------------------------------------------------------------------

// Note: Carousel can't have negative margins from sx, or the animation won't start again right from the beginning
const REFERENCE_LOGOS = [
  // {
  //   name: 'Meta',
  //   src: '/assets/images/badges/meta-partner-badge.png',
  //   sx: { opacity: 0.75, my: -2 },
  // },
  {
    name: 'Wannado',
    src: '/assets/images/reference-logos/wannado.png',
    sx: { opacity: 0.75, pb: 0.25 },
  },
  // { name: 'LeadSpark', src: '/assets/images/reference-logos/leadspark.png', sx: { opacity: 0.8, pb: 0.5 }, widthModifier: 30 },
  { name: 'Ruokaboksi', src: '/assets/images/reference-logos/ruokaboksi.png', sx: { opacity: 1 } },
  { name: 'Schoffa', src: '/assets/images/reference-logos/schoffa.webp', sx: { opacity: 0.7 } },
  {
    name: 'Stolt',
    src: '/assets/images/reference-logos/stolt.webp',
    sx: { opacity: 0.7, pb: 0.5 },
    widthModifier: -30,
  },
  { name: 'Tagomo', src: '/assets/images/reference-logos/tagomo.png', sx: { opacity: 0.7, pt: 1 } },
  { name: 'ImageLab', src: '/assets/images/reference-logos/imagelab.png', widthModifier: 30 },
  {
    name: '24Center',
    src: '/assets/images/reference-logos/24center.png',
    sx: { opacity: 0.6 },
    widthModifier: -50,
  },
  {
    name: 'Lilja the Label',
    src: '/assets/images/reference-logos/liljathelabel.png',
    sx: { opacity: 0.7, pt: 0.75, m: { xs: 0, md: -1.75 }, ml: { xs: 0, md: -2 } },
  },
];

export default function TechReferenceLogos({
  title = 'Improving ROAS for 1,000+ forward-thinking companies',
}) {
  const logos = REFERENCE_LOGOS;
  const isMobile = useResponsive('down', 'sm');

  if (isMobile) {
    const imagePx = 5;
    const speed = 4; // time per logo
    const logosWidth = logos.reduce(
      (acc, logo) => acc + (90 + imagePx * 2 * 8 + (logo.widthModifier || 0)),
      0
    );
    const renderArray = [...Array(2)];

    return (
      <RootStyle>
        <Container>
          <Stack>
            <Typography
              variant="h5"
              align="center"
              sx={{ mt: 2, mb: 1, px: 3, lineHeight: 1.2, zoom: 0.9 }}
            >
              {title}
            </Typography>
          </Stack>
          <Box
            sx={{ width: logosWidth, margin: 'auto', overflow: 'visible', filter: 'contrast(1.5)' }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: 0,
                animation: `scroll-left ${speed * logos.length}s infinite linear`,
                alignItems: 'center',
              }}
            >
              {renderArray.map((value, index) =>
                logos.map((logo) => (
                  <Box sx={{ px: imagePx }} key={`${index}-${logo.name}`}>
                    <Image
                      src={logo.src}
                      disableLazyLoad
                      sx={{
                        width: {
                          xs: 90 + (logo.widthModifier ?? 0),
                          lg: 100 + (logo.widthModifier ?? 0),
                          xl: 100 + (logo.widthModifier ?? 0),
                        },
                        filter: 'grayscale(1)',
                        ...logo.sx,
                      }}
                    />
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Container>
      </RootStyle>
    );
  }

  return (
    <RootStyle>
      <Container>
        <Stack>
          <Typography variant="h5" align="center" sx={{ mt: 3, mb: 2.5, zoom: 0.9 }}>
            {title}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ flexWrap: 'wrap', filter: 'contrast(1.5)' }}
        >
          {logos.map((logo) => (
            <Stack
              sx={{ mx: 3, my: 1, height: 50 }}
              spacing={1}
              justifyContent="center"
              alignItems="center"
              key={logo.name}
            >
              <Image
                src={logo.src}
                sx={{
                  width: {
                    xs: 90 + (logo.widthModifier ?? 0),
                    lg: 100 + (logo.widthModifier ?? 0),
                    xl: 100 + (logo.widthModifier ?? 0),
                  },
                  filter: 'grayscale(1)',
                  ...logo.sx,
                }}
              />
            </Stack>
          ))}
        </Stack>
      </Container>
    </RootStyle>
  );
}

TechReferenceLogos.propTypes = {
  title: PropTypes.string,
};
